import React from 'react';
import {isNumberObject} from "util/types";

interface IProps{
    date:string,
    qtySold:number
}

const QtySaleMonth = ({date, qtySold}:IProps) => {

    var arrayOfStrings: Array<string> = date.split(" ");
    let d:Date = new Date();
    function monthDiff(dateFrom:Date, dateTo:Date) {
        return Number(dateTo.getMonth() - dateFrom.getMonth() +
            (12 * (dateTo.getFullYear() - dateFrom.getFullYear())))
    }
    let resposne:number = (Number(qtySold) / (monthDiff(new Date(Number(arrayOfStrings[2]), Number(arrayOfStrings[1])), new Date(d.getFullYear(), (d.getMonth() + 1)))));
    if(resposne === Infinity) resposne = 0;

    return (
        <>
            {resposne.toFixed(0)}
        </>
    );
};

export default QtySaleMonth;