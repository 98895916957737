import React, {useEffect, useState, useMemo, useContext} from 'react';
import {Link, useLocation, redirect} from "react-router-dom";
import Article from "./article";
import {IProduct} from "../Modal/modal";
import axios from "axios";
import Loading from "./loading";
import Pagination from "./pagination";
import 'react-tooltip/dist/react-tooltip.css'
import {Tooltip} from "react-tooltip";
import {UserContext} from "./container";
import LoadingSkeleton from "./LoadingSkeleton";
import NotArticle from "./NotArticle";

const RightMain = () => {
    const [items, setItems] = useState<IProduct[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);
    const NumberOfPage:number = 20;
    const [linkPage, setLinkPage] = useState<string>('/');
    const [Sort, setSort] = useState<string>("");
    const { userName } = useContext(UserContext);

    let location = useLocation();

    async function GetGategory(id:number, offset:number, sort:string|boolean){
        setLoading(true);

        if(offset > 0) offset = offset - 1;
        let ofs:number = offset * NumberOfPage;

        let param:object = {category: id, get: NumberOfPage, offset: ofs}
        if (sort) Object.assign(param, {sort: sort});

        const response = await axios.get<IProduct[]>('https://apisaveitems.dscalculator.hhos.net/index.php', {
            params: param,
            withCredentials: true
        })
        setItems(response.data);
        setLoading(false);
        setPage(Number(response.headers['count']));
    }
    async function GetItems(offset:number, sort:string|boolean){

        if(offset > 0) offset = offset - 1;
        let ofs:number = offset * NumberOfPage;

        let param:object = {get: NumberOfPage, offset: ofs}
        if (sort) Object.assign(param, {sort: sort});

        setLoading(true);
        const response = await axios.get<IProduct[]>('https://apisaveitems.dscalculator.hhos.net/index.php', {
            params: param,
            withCredentials: true
        })

        setItems(response.data);
        setLoading(false);
        setPage(Number(response.headers['count']));
    }



    useEffect(() => {
        // Обновляем заголовок документа с помощью API браузера
        let arr = location.pathname.trim().split('/');
        let sp:Array<string> = location.search.split("page=");
        let sort:Array<string>= location.search.split("sort=");
        let sor:string = "";

        if(sort[1] !== undefined) {
            if(sort[1] === "priceHigh" || sort[1] === "priceLow") sor = sort[1];
        }else sor = "";
        setSort(sor);

        let offset:number = 0;
        if(sp[1] !== undefined) {
            let res:string[] = sp[1].split("&");
            offset = Number(res[0]);
        }
        if(arr[1] !== undefined) setLinkPage(arr[1]);

        if (arr[1] === 'category') GetGategory(Number(arr[2]), offset, sor);
        //else if(arr[1] === 'saved') GetSaved(offset, sor);
        //else if(arr[1] === 'rejected') GetRejected(offset, sor);
        else GetItems(offset, sor)

    }, [location]);

    return (
        <div className="flex-1 rounded-md">
            {/*<ul className="nav nav-tabs">
                    <li className="nav-item dropdown">
                        <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                           aria-expanded="false">Sort by:  </button>
                        <ul className="dropdown-menu">
                            <li><Link className={Sort === "" ? 'dropdown-item active' : 'dropdown-item'} to="/">Standard</Link></li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li><Link className={Sort === 'priceHigh' ? 'dropdown-item active' : 'dropdown-item'} to="?sort=priceHigh">Price: Low to High</Link></li>
                            <li><Link className={Sort === 'priceLow' ? 'dropdown-item active' : 'dropdown-item'} to="?sort=priceLow">Price: High to Low</Link></li>

                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className={linkPage === "/" || linkPage === "" || linkPage === "category" ? "nav-link active" : "nav-link"} aria-current="page" to="/">All goods</Link>
                    </li>
                    <li className="nav-item my-anchor-element" data-tooltip-content={userName === "" ? "Available after authorization" : ""}>
                        <Link className={linkPage === "saved" ? "nav-link active" : userName === "" ? "nav-link disabled" : "nav-link"} to="/saved">Saved</Link>
                    </li>
                    <li className="nav-item my-anchor-element" data-tooltip-content={userName === "" ? "Available after authorization" : ""}>
                        <Link className={linkPage === "rejected" ? "nav-link active" : userName === "" ? "nav-link disabled" : "nav-link"} to="/rejected">Rejected</Link>
                    </li>
                </ul>*/}
            {
                loading ? <LoadingSkeleton height={100}/>
                    : items.length === 0 ? <NotArticle />
                    : items.map((item) => <Article key={item.ID} product={item}/>)
            }
            <Pagination CountPage={page} countItem={NumberOfPage} CountArray={items.length} Sort={Sort} />
        </div>
    );
};

export default RightMain;