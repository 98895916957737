import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import {Interface} from "readline";
import {IProduct, IProductSave} from "../Modal/modal";
import Qty_sale_month from "./qty_sale_month";
import QtySaleMonth from "./qty_sale_month";
import 'react-tooltip/dist/react-tooltip.css'
import {Tooltip} from "react-tooltip";
import axios from "axios";
import {UserContext} from "./container";

interface productProps {
    product:IProduct
}

interface IEdit{
    Success:number
}

const Article = ({product}: productProps) => {

    const [save, setSave] = useState<number>(product.Saved);
    const [rejecte, setRejecte] = useState<number>(product.Rej);
    const { userName } = useContext(UserContext);
    async function Request(action:string, id:number){
        const response = await axios.get<IEdit>('https://apisaveitems.dscalculator.hhos.net/index.php', {
            params: {edit: action, sell: id, site:1},
            withCredentials: true
        })
    }

    function Save(id:number, s:number):void{
        setSave(s);
        if (rejecte === 1) setRejecte(0);
        Request('Save', id);
    }

    function Rejected(id:number, s:number):void{
        setRejecte(s);
        if(save === 1) setSave(0);
        Request('Rejected', id);
    }

    function getMinImg(img:any){
        let imgs: Array<string> = img.split("/");
        return 'https://i.ebayimg.com/thumbs/images/g/' + imgs[7] + '/s-l225.webp';
    }

    const Img:string = getMinImg(product.Img);
    return (
        <>{
            <article className="border-2 rounded pt-2.5 mt-4 relative">
                <div className="float-left w-52 pl-2.5 flex justify-center" id="MainImg"><img src={Img} className="rounded-2xl" alt={product.MainTitle}/></div>
                <div className="ml-56">
                    <div className="">{product.MainTitle}</div>
                    <div className="mt-2">Price: <span className="font-medium">${product.PriceItem}</span></div>
                    <div className="mt-2">Category: <span className="font-medium">{product.Category}</span></div>
                    <div className="mt-2">Brand: <span className="font-medium">{product.Bd}</span></div>
                    <div className="mt-2">Shipping: <span className="font-medium">${Number(product.Shipping)}</span></div>
                    <div className="mt-2">Walmart: <span className="font-medium">25.99</span></div>
                    <div className="mt-2">Total: <span className="font-semibold text-lime-500">${Number(Number(product.PriceItem) + Number(product.Shipping)).toFixed(2)}</span></div>
                </div>
                {save === 0 && userName !== "" ?
                    <svg onClick={() => Save(Number(product.ID), 1)} width="30" height="30" fill="currentColor" className="bi bi-bookmark absolute bottom-12 right-14 cursor-pointer" viewBox="0 0 16 16">
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/> alt="fff"
                    </svg>
                    : userName !== "" ?
                    <svg onClick={() => Save(Number(product.ID), 0)} width="30" height="30" fill="currentColor" className="bi bi-bookmark-fill absolute bottom-12 right-14 cursor-pointer" viewBox="0 0 16 16">
                        <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z"/>
                    </svg>
                    : ''
                }

                {rejecte === 0 && userName !== "" ?
                    <svg onClick={() => Rejected(Number(product.ID), 1)} width="30" height="30" fill="currentColor" className="bi bi-dash-square absolute bottom-12 right-2 cursor-pointer" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                    </svg>
                    : userName !== "" ?
                    <svg onClick={() => Rejected(Number(product.ID), 0)} width="30" height="30" fill="currentColor" className="bi bi-dash-square-fill absolute bottom-12 right-2 cursor-pointer" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z"/>
                    </svg>
                    :''
                }

                <div className="clear-both"></div>
                <div className="mt-2.5 bg-zinc-100 p-2 border-t flex justify-between">
                    <div className="my-anchor-element" data-tooltip-content="Average sales per month (number of items sold / number of months the item was sold)">S/M: ~ <span className="font-medium">{<QtySaleMonth date={product.CreateListing} qtySold={product.QytSold}/>}</span></div>
                    <div>Sold: <span className="font-medium">{product.QytSold}</span></div>
                    <div>Quantity: <span className="font-medium">{product.Qty}</span></div>
                    <div>Created listing: <span className="font-medium">{product.CreateListing}</span></div>
                    <div>Duration: <span className="font-medium">{product.Duration}</span></div>
                </div><Tooltip anchorSelect=".my-anchor-element" />
            </article>

        }
        </>
    );
};

export default Article;