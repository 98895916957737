import React, {useEffect, useState} from 'react';
import LoadingSkeleton from "./LoadingSkeleton";
import axios from "axios";
import {IProduct, IProductSave} from "../Modal/modal";
import {useLocation} from "react-router-dom";
import Loading from "./loading";
import Article from "./article";
import Pagination from "./pagination";
import NotArticle from "./NotArticle";
import SaveItem from "./SaveItem";

const Item = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [items, setItems] = useState<IProductSave[]>([]);
    const [page, setPage] = useState<number>(0);
    const [Sort, setSort] = useState<string>("");
    const NumberOfPage:number = 20;
    let location = useLocation();


    async function GetSaved(page:number, sort:string|boolean){
        setLoading(true);
        if(page > 0) page = page - 1;
        let ofs:number = page * NumberOfPage;

        let param:object = {get: NumberOfPage, offset: ofs, item: 'saved'}
        if (sort) Object.assign(param, {sort: sort});

        const response = await axios.get<IProductSave[]>('https://apisaveitems.dscalculator.hhos.net/index.php', {
            params: param,
            withCredentials: true
        })
        setItems(response.data);
        setLoading(false);
        setPage(Number(response.headers['count']));
    }

    async function GetRejected(page:number, sort:string|boolean){
        setLoading(true);

        if(page > 0) page = page - 1;
        let ofs:number = page * NumberOfPage;

        let param = {get: NumberOfPage, offset: ofs, item: 'rejected'}
        if (sort) Object.assign(param, {sort: sort});

        const response = await axios.get<IProductSave[]>('https://apisaveitems.dscalculator.hhos.net/index.php', {
            params: param,
            withCredentials: true
        })

        setItems(response.data);
        setLoading(false);
        setPage(Number(response.headers['count']));
    }


    useEffect(() => {
        // Обновляем заголовок документа с помощью API браузера
        let arr = location.pathname.trim().split('/');
        let sp:Array<string> = location.search.split("page=");
        let sort:Array<string>= location.search.split("sort=");
        let sor:string = "";

        if(sort[1] !== undefined) {
            if(sort[1] === "priceHigh" || sort[1] === "priceLow") sor = sort[1];
        }else sor = "";
        setSort(sor);

        let offset:number = 0;
        if(sp[1] !== undefined) {
            let res:string[] = sp[1].split("&");
            offset = Number(res[0]);
        }

       if(arr[1] === 'saved') GetSaved(offset, sor);
       else if(arr[1] === 'rejected') GetRejected(offset, sor);

    }, [location]);

    return (
        <>
            {loading ? <LoadingSkeleton height={60} />
                : items.length === 0 ? <NotArticle />
                : items.map((item) => <SaveItem key={item.ItemID} product={item} />)
            }
            <Pagination CountPage={page} countItem={NumberOfPage} CountArray={items.length} Sort={Sort} />
        </>
    );
};

export default Item;