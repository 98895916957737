import React from 'react';

const NotArticle = () => {
    return (
        <div className="text-center mt-4">
            <h1>No Items</h1>
        </div>
    );
};

export default NotArticle;