import React, {useEffect, useState} from 'react';
import {IProductSave} from "../Modal/modal";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";

interface IProps{
    product:IProductSave
}
interface IEdit{
    Success:number
}

const SaveItem = ({product}:IProps) => {

    const [rejecte, setRejecte] = useState<number>(0);
    const [view, setView] = useState<boolean>(true);
    const [arr, setArr] = useState<any>([]);
    let location = useLocation();


    async function Request(action:string, id:number){
        const response = await axios.get<IEdit>('https://apisaveitems.dscalculator.hhos.net/index.php', {
            params: {edit: action, sell: id, site:1},
            withCredentials: true
        })
    }

    function Delete(id:number):void{
        Request('delete', id);
        setView(false);
    }

    function Rejected(id:number, s:number):void{
        if(s === 1) setView(false);
        setRejecte(s);
        Request('Rejected', id);
    }

    useEffect(() => {
        let arr1 = location.pathname.trim().split('/');
        setArr(arr1);

    }, [location]);


    let percent:string|number = Number('0.'+product.Fees);
    let Profit:string = (((Number(product.SellPrice) + Number(product.SellShipping)) - ((Number(product.SellPrice) + Number(product.SellShipping)) * percent)) - (Number(product.PriceSupplier) + Number(product.ShippingSupplier))).toFixed(2);
    let prof:number = Number(Profit);

    //`/posts/${props.post.id}`)

    return (
        <>
            {view &&
            <article className="border-2 rounded mt-2 min-h-20 flex">
                <div className="flex justify-center items-center w-16 h-20 ml-2" ><img src={product.Img} className="rounded-sm" alt={product.Img}/></div>
                <div className="ml-4 mt-2 w-60 text-sm">{product.Title}</div>
                <div className="ml-2 border-l-2 border-r-2 w-24 flex justify-center items-center">{product.SellPrice}</div>
                <div className="border-r-2 w-16 flex justify-center items-center">{Number(product.SellShipping)}</div>
                <div className="border-r-2 w-24 flex justify-center items-center">{product.PriceSupplier}</div>
                <div className="border-r-2 w-16 flex justify-center items-center">{product.ShippingSupplier}</div>
                <div className="border-r-2 w-16 flex justify-center items-center">{product.Fees}</div>
                <div className={prof <= 0 ? 'border-r-2 w-28 flex justify-center items-center font-semibold text-rose-500' : 'border-r-2 w-28 flex justify-center items-center font-semibold text-green-400'}>{Profit}</div>
                <div className="border-r-2 w-20 flex justify-center items-center text-xs"><Link to={product.SellLink} target="_blank" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">{product.SellName}</Link></div>
                <div className="border-r-2 w-20 flex justify-center items-center text-xs"><Link to={product.SupplierLink} target="_blank" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">{product.SupplierName}</Link></div>
                <div className="w-36 flex justify-center items-center text-xs relative">
                <svg  onClick={() => Delete(product.ItemID)} width="30" height="30" fill="currentColor" className="bi bi-dash-square absolute my-auto top-0 bottom-0 right-18 cursor-pointer" viewBox="0 0 16 16">
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                </svg>

                {rejecte === 0 && arr[1] === 'saved'?
                    <svg onClick={() => Rejected(Number(product.ItemID), 1)} width="30" height="30" fill="currentColor" className="bi bi-dash-square absolute my-auto top-0 bottom-0 right-2 cursor-pointer" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                    </svg>
                    : arr[1] === 'saved'?
                    <svg onClick={() => Rejected(Number(product.ItemID), 0)} width="30" height="30" fill="currentColor" className="bi bi-dash-square-fill absolute my-auto top-0 bottom-0 right-2 cursor-pointer" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z"/>
                    </svg>
                    : ''
                }
                </div>
            </article>
            }
        </>
    );
};

export default SaveItem;