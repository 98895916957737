import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import Header from "./header";
import Footer from "./footer";
import {Outlet, Link, useRoutes, useParams, Route, Routes, useLocation} from "react-router-dom";

export const UserContext = createContext({
    userName: "",
    setUserName: () => {},
});
const Container = () => {
    const [co, setC] = useState('');
    const [Sort, setSort] = useState<string>("");
    const [linkPage, setLinkPage] = useState<string>('/');

    const [userName, setUserName] = useState("");
    const value:any = useMemo(
        () => ({ userName, setUserName }),
        [userName]
    );
    let location = useLocation();

    useEffect(() => {
        let arr = location.pathname.trim().split('/');
        let sp:Array<string> = location.search.split("page=");
        let sort:Array<string>= location.search.split("sort=");
        let sor:string = "";

        if(sort[1] !== undefined) {
            if(sort[1] === "priceHigh" || sort[1] === "priceLow") sor = sort[1];
        }else sor = "";
        setSort(sor);

        let offset:number = 0;
        if(sp[1] !== undefined) {
            let res:string[] = sp[1].split("&");
            offset = Number(res[0]);
        }
        if(arr[1] !== undefined) setLinkPage(arr[1]);

    }, [location]);

    return (
        <>
        <UserContext.Provider value={value}>
            {useMemo(() => (
                <div className="border-2 rounded p-2">
                    <Header/>
                    <ul className="nav nav-tabs">
                        <li className="nav-item dropdown">
                            <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                                    aria-expanded="false">Sort by:  </button>
                            <ul className="dropdown-menu">
                                <li><Link className={Sort === "" ? 'dropdown-item active' : 'dropdown-item'} to={linkPage === "saved" ? "/saved" : linkPage === "rejected" ? "/rejected" : '/'}>Standard</Link></li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li><Link className={Sort === 'priceHigh' ? 'dropdown-item active' : 'dropdown-item'} to="?sort=priceHigh">Price: Low to High</Link></li>
                                <li><Link className={Sort === 'priceLow' ? 'dropdown-item active' : 'dropdown-item'} to="?sort=priceLow">Price: High to Low</Link></li>

                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className={linkPage === "/" || linkPage === "" || linkPage === "category" ? "nav-link active" : "nav-link"} aria-current="page" to="/">All goods</Link>
                        </li>
                        <li className="nav-item my-anchor-element" data-tooltip-content={userName === "" ? "Available after authorization" : ""}>
                            <Link className={linkPage === "saved" ? "nav-link active" : userName === "" ? "nav-link disabled" : "nav-link"} to="/saved">Saved</Link>
                        </li>
                        <li className="nav-item my-anchor-element" data-tooltip-content={userName === "" ? "Available after authorization" : ""}>
                            <Link className={linkPage === "rejected" ? "nav-link active" : userName === "" ? "nav-link disabled" : "nav-link"} to="/rejected">Rejected</Link>
                        </li>
                        <li className="nav-item my-anchor-element" data-tooltip-content={userName === "" ? "Available after authorization" : ""}>
                            <Link className={linkPage === "settings" ? "nav-link active" : userName === "" ? "nav-link disabled" : "nav-link"} to="/settings">Settings</Link>
                        </li>
                    </ul>
                    <main>
                        <Outlet/>
                    </main>
                    {/*<Footer/>*/}
                </div>
            ), [Sort, linkPage, userName])}
        </UserContext.Provider>
        </>
    );
};

export default Container;
