import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import {ICategory, IProduct} from "../Modal/modal";
import {arrow} from "@popperjs/core";
import * as buffer from "buffer";
import Loading from "./loading";
import {Skeleton} from "@mui/material";
import LoadingSkeleton from "./LoadingSkeleton";

export interface CategoryView{
    ID?:number,
    Name?:string,
    Parend:number,
    Level?:number,
    Children:CategoryView[]
}


const Category = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [category, setCategory] = useState<ICategory[]>([]);
    let location = useLocation();
    let arr:string[] = location.pathname.trim().split('/');
    let catID:number = 0;

    if(arr[2] !== undefined) catID = Number(arr[2]);

   function SortCategory(data:ICategory[]):void{

       let testsSortedByNome = data.sort((a:any, b:any) => (a.Lv < b.Lv ? -1 : 1));

       let Cat:CategoryView[][] = [];

        for (let a = 0; a < testsSortedByNome.length; a++){
            let C = {} as CategoryView;
            C.ID = Number(testsSortedByNome[a]['ID']);
            C.Name = testsSortedByNome[a]['Name'];
            C.Level = Number(testsSortedByNome[a]['Lv']);
            C.Parend = Number(testsSortedByNome[a]['Parend']);
            C.Children = [];
            if(Cat[C.Level - 1] === undefined) Cat[C.Level - 1] = [];
            Cat[C.Level - 1].push(C);
        }

        let Catego = [];
        let key:number = 0;


        /*
       let myMap:any = new Map();
       for(let a = testsSortedByNome.length-1; a >= 0; a--){
           myMap.set(testsSortedByNome[a]['ID'], testsSortedByNome[a]);
       }

       let map = null;

       for(let entry of myMap){
           if(myMap.has(entry[1]['Parend'])){
               map = myMap.get(entry[1]['Parend']);
               if(map['Children'] === undefined) Object.assign(map, {'Children': new Map()});
               map['Children'].set(entry[0], entry[1]);
               myMap.set(entry[1]['Parend'], map);
               myMap.delete(entry[0]);
           }
       }

       const sortedDesc = new Map([...myMap].sort((a, b) => (a[1]['Name'] < b[1]['Name'] ? -1 : 1)));


       let obj = Object.fromEntries(sortedDesc.entries());
       Object.keys(obj).map((ob) => console.log(sortedDesc.get(ob)));

       //obj.map((ob) => console.log(obj));
        */

       for(let i = Cat.length-1; i >= 0; i--){
           Catego = Cat[i];

           for(let b = Catego.length-1; b >= 0; b--){
               key = Number(Catego[b]['Level']) - 2;

               if(Cat[key] !== undefined){
                    for (let c = 0; c < Cat[key].length; c++){
                       if(Number(Catego[b]['Parend']) === Number(Cat[key][c]['ID'])){
                           Cat[key][c]['Children'].push(Catego[b]);
                       }
                    }
               }
           }
       }
        setCategory(Cat[0]);
       setLoading(false);
    }

   async function GetCategory(){
        const response = await axios.get<ICategory[]>('https://apisaveitems.dscalculator.hhos.net/index.php', {
            params: {
                category: 'All'
            },
            withCredentials:true
        });
        return response.data;
    }

    useEffect(() => {

        GetCategory().then(response => SortCategory(response));


    }, []);

    return (
        <menu>
            <p>Category</p>
            <p className="mb-0 CatecoryName"><Link to="/" className={catID === 0 ? 'no-underline text-black font-bold' : 'no-underline'}>All</Link></p>
            {loading ? <LoadingSkeleton height={25} />
                : category.map((cat) => <p key={cat.ID} className="mb-2 CatecoryName"><Link to={`/category/${cat.ID}`} className={catID === cat.ID ? 'no-underline text-black font-bold' : 'no-underline'}>{cat.Name}</Link></p>)


            }
        </menu>
    );
};

export default Category;