import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Skeleton} from "@mui/material";
import {IFees} from "../Modal/modal";

const SettinsAccount = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [valueEbay, setEbay] = useState<string>('');
    const [valueAmazon, setAmazon] = useState<string>('');
    const [valueWalmart, setWalmart] = useState<string>('');

    useEffect(() => {
        GetFees();

    }, []);

    async function GetFees(){
        const response = await axios.get<IFees>('https://apisaveitems.dscalculator.hhos.net/index.php', {
            params: {edit: 'getFees'},
            withCredentials: true
        })
        setEbay(response.data['Ebay']);
        setAmazon(response.data['Amazon']);
        setWalmart(response.data['Walmart']);
        setLoading(false);
    }
//walmart
    async function form(e:any, site:string){
        e.preventDefault();
        setLoading(true);
        let input:number = Number(e.target[0].value.trim());
        if(isNaN(input)) return false;

        const response = await axios.get<string>('https://apisaveitems.dscalculator.hhos.net/index.php', {
            params: {edit: 'setting', fees: input, site: site},
            withCredentials: true
        })
        setLoading(false);
    }

    async function Inp(e:any){
        console.log(e.target.value);
    }

    return (
        <div className="mt-4 ml-4">
            <div className="flex items-center">Ebay seller Fees:
                <form onSubmit={event => form(event,'ebay')} className="flex items-center ml-4">
                    {loading ? <Skeleton height={40} width={150} />
                        : <div className="input-group">
                            <input value={valueEbay} onChange={e => setEbay(e.target.value)} type="number" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="submit">Send</button>
                            </div>
                        </div>
                   }
                </form>
            </div>
            <div className="flex items-center mt-4">Amazon seller Fees:
                <form onSubmit={event => form(event, 'amazon')} className="flex items-center ml-4">
                    {loading ? <Skeleton height={40} width={150} />
                        : <div className="input-group">
                            <input value={valueAmazon} onChange={e => setAmazon(e.target.value)} type="number" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="submit">Send</button>
                            </div>
                        </div>
                    }
                </form>
            </div>
            <div className="flex items-center mt-4">Walmart seller Fees:
                <form onSubmit={event => form(event, 'walmart')} className="flex items-center ml-4">
                    {loading ? <Skeleton height={40} width={150} />
                        : <div className="input-group">
                            <input value={valueWalmart} onChange={e => setWalmart(e.target.value)} type="number" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="submit">Send</button>
                            </div>
                        </div>
                    }
                </form>
            </div>
        </div>
    );
};

export default SettinsAccount;