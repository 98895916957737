import React, {useState} from 'react';
import {useGoogleLogin} from "@react-oauth/google";

interface IProps{
    func: any
}

const Auth = ({func}:IProps) => {

    const login = useGoogleLogin({
        onSuccess: codeResponse => func(codeResponse),
        flow: 'implicit',
    });

    return (
        <div>
            <button onClick={() => login()} className="authButton pt-2 pr-4 pb-2 pl-12 border-2 rounded-3xl">
                Sign in with Google
            </button>
        </div>
    );
};

export default Auth;