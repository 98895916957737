import React from 'react';
import Header from "./components/header";
import Footer from "./components/footer";
import type { RouteObject } from "react-router-dom";
import { Outlet, Link, useRoutes, useParams, Route, Routes} from "react-router-dom";
import Container from "./components/container";
import Main from "./components/main";
import Item from "./components/item";
import SettinsAccount from "./components/SettinsAccount";

function App() {

    return (
        <>
            <Routes>
                <Route path="/" element={<Container />}>
                    <Route path="item/:itemId" element={<Item />} />
                    <Route path="category/:ID" element={<Main />} />
                    <Route path="saved" element={<Item />} />
                    <Route path="rejected" element={<Item />} />
                    <Route path="settings" element={<SettinsAccount />} />
                    <Route path="/" element={<Main />} />
                </Route>
                {/*<Route path="ffffproduct/:itemId" element={<Header />} />*/}
            </Routes>
        </>
    );

}

export default App;
