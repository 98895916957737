import React from 'react';
import Category from "./category";




const LeftMain = () => {
    return (
        <div className="border-2 flex-2 w-60 mr-5 mt-4 p-2 rounded-md">
            <Category/>
        </div>
    );
};

export default LeftMain;