import React, {useState} from 'react';
import {Link} from "react-router-dom";
import LeftMain from "./left_main";
import RightMain from "./right_main";

const Main = () => {

    return (
        <div className="flex items-start">
            <LeftMain/>
            <RightMain/>
        </div>
    );
};

export default Main;