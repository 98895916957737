import React from 'react';
import {Skeleton} from "@mui/material";

interface Iprops{
    height:number
}

const LoadingSkeleton = ({height}:Iprops) => {
    return (
        <>
            <Skeleton height={height} />
            <Skeleton height={height} />
            <Skeleton height={height} />
            <Skeleton height={height} />
            <Skeleton height={height} />
        </>
    );
};

export default LoadingSkeleton;