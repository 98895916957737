import React from 'react';
import {Link, useLocation} from "react-router-dom";

interface IPagination{
    countItem: number
    CountPage:number,
    CountArray:number,
    Sort:string
}

const Pagination = ({countItem, CountPage, CountArray, Sort}:IPagination) => {
    let location = useLocation();
    let sp:Array<string> = location.search.split("page=");
    let page:number = 0;
    let PageNumber:number = 1;

    if(sp[1] !== undefined){
        let res:string[] = sp[1].split("&");
        PageNumber = Number(res[0]);
    }
    if(CountPage > 0) page = Math.ceil(Number(CountPage) / Number(countItem));

    let prev:number = PageNumber - 1;
    if(prev < 1) prev = 1;

    let next: number = PageNumber + 1;
    if(next > page) next = page;

    let one:number = 1;
    let two:number = 2;

    let three:number = 3;

    let four:number = 4;
    let five:number = 5;

    if(PageNumber > 2){
        one = PageNumber - 2;
        two = PageNumber - 1;
        three = PageNumber;
        four = PageNumber + 1;
        five = PageNumber + 2;
    }

    let sort:string = "";
    if(Sort !== "") sort = "&sort=" + Sort;


    /*
    <nav aria-label="">
            <ul className="pagination justify-center mt-12">
                <li className="page-item disabled">
                    <Link to="/" className="page-link">Previous</Link>
                </li>
                <li className="page-item"><Link className="page-link" to="/">1</Link></li>
                <li className="page-item active" aria-current="page">
                    <Link className="page-link" to="/">2</Link>
                </li>
                <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                <li className="page-item">
                    <Link className="page-link" to="/">Next</Link>
                </li>
            </ul>
        </nav>*/


    /*

*{variable == 0 ?
  <Element1/>
:variable == 1 ?
  <Element2/>
:variable == 2 ?
  <Element3/>
:
  <Element4/>
}



{condition1 ?
(<div>condition1 true</div>)
:(condition2) ?
(<div>condition2 true</div>)


*/



    return (
            <>
            {CountArray > 0 &&
             <nav aria-label="">
                <ul className="pagination justify-center mt-12">
                    <li className={PageNumber === 1 ? 'page-item disabled' : 'page-item'}>
                        <Link to={"?page="+prev+sort} className="page-link">Previous</Link>
                    </li>

                    <li className={one === PageNumber ? 'page-item active' : 'page-item'}>
                        <Link className="page-link" to={"?page="+one+sort}>{one}</Link>
                    </li>

                    <li className={two === PageNumber ? 'page-item active'
                        : PageNumber < two && (PageNumber + 1) > page ? 'page-item disabled'
                            : 'page-item'
                    }>
                        <Link className="page-link" to={"?page="+two+sort}>{two}</Link>
                    </li>

                    <li className={three === PageNumber ? 'page-item active'
                            : three > page ? 'page-item disabled'
                            : 'page-item'
                    }>
                        <Link className="page-link" to={"?page="+three+sort}>{three}</Link>
                    </li>

                    <li className={four === PageNumber ? 'page-item active'
                        : four > page ? 'page-item disabled'
                            : 'page-item'
                    }>
                        <Link className="page-link" to={"?page="+four+sort}>{four}</Link>
                    </li>

                    <li className={five === PageNumber ? 'page-item active'
                        : five > page ? 'page-item disabled'
                            : 'page-item'
                    }>
                        <Link className="page-link" to={"?page="+five+sort}>{five}</Link>
                    </li>


                    <li className={(PageNumber + 1) > page ? 'page-item disabled' : 'page-item'}>
                        <Link className="page-link" to={"?page="+next+sort}>Next</Link>
                    </li>
                </ul>
            </nav>
            }
            </>
    );
};

export default Pagination;