import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import Auth from "./auth";
import axios from "axios";
import {IAuth} from "../Modal/modal";
import {Base64} from "js-base64";
import {Skeleton} from "@mui/material";
import {UserContext} from "./container";


const Header = () => {
    const [User, setUser] = useState<string|boolean>(true);
    const {userName, setUserName} = React.useContext<any>(UserContext);

    async function CheckAuth(){
        const response = await axios.get<IAuth>('https://apisaveitems.dscalculator.hhos.net/auth.php',{
            withCredentials: true,
            params: {
                check: 1
            }
        });
        setUser(response.data['Check']);
        return response.data['Check'];
    }

    useEffect(() => {
        CheckAuth().then(e => setUserName(e));
    }, [setUserName]);

    async function AuthButton(res:any){
        setUser(true);
        const response = await axios.get<IAuth>('https://apisaveitems.dscalculator.hhos.net/auth.php',{
            withCredentials: true,
            params: {
                code: Base64.encode(res.access_token)
            }
        });
        setUserName(response.data['Name']);
        setUser(response.data['Name']);
    }

    async function Logout(){
        setUser(true);
        const response = await axios.get<IAuth>('https://apisaveitems.dscalculator.hhos.net/auth.php',{
            withCredentials: true,
            params: {
                Logout: 1
            }
        });
        setUserName(response.data['Check']);
        setUser(response.data['Check']);
    }


    return (
        <header className="h-28 mb-4 border-b-2 flex justify-between items-center">
            <div className="ml-24"><Link to="/" className="text-black no-underline font-semibold text-3xl">SaveItems</Link></div>
            <div className="mr-24 p-2 rounded-xl h-16 flex items-center">
                {User === "" ?
                        <GoogleOAuthProvider clientId="326420469528-56n2s9nne27tlmgnh2kjo5u6544qkr2o.apps.googleusercontent.com">
                            <Auth func={AuthButton}/>
                        </GoogleOAuthProvider>
                    : User === true ? <Skeleton variant="rounded" width={210} height={24} />
                    : <p className="mb-0">Hello, {User} | <button onClick={Logout} type="button" className="ButtonSign btn btn-dark btn-sm pt-0 pr-2 pb-0 pl-2">Sign out</button></p>
                }
            </div>
        </header>
    );
};

export default Header;